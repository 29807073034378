<template>
  <div id="app">
    <v-app>
      <v-content>

        <router-view/>

        <v-bottom-navigation
            v-if="show_tabbar"
            v-model="selected"
            :fixed="true"
            :grow="true"
            color="primary lighten-1"
          >
          <v-btn value="TmsRouteList">
            <span>TMS Route</span>
            <v-icon>mdi-history</v-icon>
          </v-btn>

          <v-btn value="setting">
            <span>Setting</span>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </v-bottom-navigation>

      </v-content>
    </v-app>
  </div>
</template>

<script>
import {EH_MOBILE_GLOBAL} from '@/helper.js'
// import axios from 'axios'
import { post } from '@/utils/request.js'

export default {
  name: 'App',

  components: {

  },

  data () {
    return {
      selected: 'recent',
      show_tabbar: false
    }
  },

  watch: {
    selected (newval, oldval) {
      console.log(['App.vue watch v-bottom-navigation selected', newval, oldval])
      switch (newval) {
        case 'TmsRouteList':
          if (window.location.hash.substr(1) == '/tms/route/list') {
            break
          }
          this.$router.push({
            path: '/tms/route/list'
          })
          break
        case 'setting':
          if (window.location.hash.substr(1) == '/setting') {
            break
          }
          this.$router.push({
            path: '/setting'
          })
          break
      }
    },
    '$route' (to, from) {
      if (to.name === 'Login' || to.name === 'Login2') {
        this.show_tabbar = false
      } else {
        this.show_tabbar = true
      }

      console.log(['App.vue watch $route', from])
    }
  },

  methods: {
    testLogin: function () {
      post(EH_MOBILE_GLOBAL.login_url, '', {}).then((response) => {
        let data = response.data
        let hash_url = window.location.hash.substr(1)
        // console.log([
        //   data,
        //   hash_url
        // ])
        if (data.status !== 'success' && hash_url !== '/') {
          this.$router.push({
            name: 'Login'
          })
        }
      })
    }
  },

  created () {
    this.testLogin()


    // this.$router.push({
    //   path: window.location.pathname
    // })

    // console.log(window.location.pathname)
    // switch (window.location.pathname) {
    //   case '/eh/mobile/shipments':
    //     this.selected = 'shipments'
    //     break
    //   // case '/eh/mobile/shipments/completed':
    //   //   this.selected = 'completedShipments'
    //   //   break
    //   case '/nsapp/mobile/shipments':
    //     this.selected = 'nsappShipments'
    //     break
    //   case '/eh/mobile/setting':
    //     this.selected = 'setting'
    //     break
    //   case '/eh/mobile/scan':
    //     this.selected = 'scan'
    //     break
    // }
  }
};
</script>
