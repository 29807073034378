var EH_MOBILE_GLOBAL = {
//   url: 'http://192.168.1.108:7013', // debug
//   public_url: 'http://192.168.1.108:7013', // debug

  url: '/tms/driver/mobile',
  public_url: '',
}

// eh module url
EH_MOBILE_GLOBAL.login_url = EH_MOBILE_GLOBAL.url + '/tms/driver/mobile/login'
EH_MOBILE_GLOBAL.logout_url = EH_MOBILE_GLOBAL.url + '/tms/driver/mobile/logout'
EH_MOBILE_GLOBAL.get_route_list = EH_MOBILE_GLOBAL.url + '/tdm/get/route/list'
EH_MOBILE_GLOBAL.get_tms_order_list = EH_MOBILE_GLOBAL.url + '/tdm/get/route/tms/order/list'
EH_MOBILE_GLOBAL.get_tms_order_detail = EH_MOBILE_GLOBAL.url + '/tdm/get/route/tms/order/detail'
EH_MOBILE_GLOBAL.get_tms_order_task_status_info = EH_MOBILE_GLOBAL.url + '/tdm/get/tms/order/task/status/item'
EH_MOBILE_GLOBAL.save_tms_order_task_status = EH_MOBILE_GLOBAL.url + '/tdm/save/tms/order/task/status'
EH_MOBILE_GLOBAL.save_signature_img = EH_MOBILE_GLOBAL.url + '/tdm/save/signature/img'
EH_MOBILE_GLOBAL.tdm_save_big_file = EH_MOBILE_GLOBAL.url + '/tdm/save/big/file'
EH_MOBILE_GLOBAL.tdm_save_task_photo = EH_MOBILE_GLOBAL.url + '/tdm/save/task/photo'

var EH_MOBILE_FUN = {}
EH_MOBILE_FUN.serialize = function (obj, prefix) {
  var str = []
  var p
  for (p in obj) {
    if (typeof obj == 'object' && Object.prototype.hasOwnProperty.call(obj, p)) {
      var k
      var v
      k = prefix ? prefix + '[' + p + ']' : p
      v = obj[p]
      str.push((v !== null && typeof v === 'object') ? EH_MOBILE_FUN.serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v))
    }
  }
  return str.join('&')
}

EH_MOBILE_FUN.readFile = function (file, fnc) {
  var reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function (e) {
    if (typeof fnc === 'function') {
      fnc(e.target.result)
    }
  }
}

EH_MOBILE_FUN.randomStr = function (length) {
  var result = ''
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

EH_MOBILE_FUN.isEmptyObj = function (obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

EH_MOBILE_FUN.isNumber = function (str) {
  str += ''

  if (str.match(/^-{0,1}\d+$/)) {
    return true
  } else if (str.match(/^\d+\.\d+$/)) {
    return true
  }

  return false
}

EH_MOBILE_FUN.round2 = function (num) {
  return Math.round(num * 100) / 100
}

EH_MOBILE_FUN.getRightNum = (val) => {
  let tmpRet = parseFloat(val)
  if (isNaN(tmpRet)) {
    tmpRet = 0
  }
  return tmpRet
}

export {EH_MOBILE_GLOBAL, EH_MOBILE_FUN}
